import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BuiltWithPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="TCB Technology Website Tech Stack" />

      <h1>Built With</h1>

      <p>
        This page is where I keep some notes for myself about the project - what I have done and what I would like to do.
      </p>

      <p>
        This site was built using <a href="https://www.gatsbyjs.com/">Gatsby</a> and hosted on <a href="https://www.netlify.com/">Netlify</a>.
      </p>

      <p>
        Technical features uses are:
        <ul className="tech-items">
          <li>Node.js</li>
          <li>React</li>
          <li>GitHub</li>
          <li>Sass</li>
          <li>WordPress</li>
        </ul>
      </p>

      <p>
        Technical features planned are:
        <ul className="tech-items">
          <li>Media Queries</li>
        </ul>
      </p>

      
    </Layout>
  )
}

export default BuiltWithPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`